import EncryptUtility from "../../utility/js/encrypt-utility";
import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import AddFavourite from "../../utility/js/addFav";
import Utility from "../../../shared/utility";
import servicePO from "../js/common-po-service";
import commonService from "../../common/js/common-api-service";
export default {
  data: () => ({
    backSlash: true,
    refresh: true,
    userId: EncryptUtility.localStorageDecrypt("userID"),
    actionList: [],
    mainPage: "",
    favouriteList: [],
    pageUrl: "",
    subPage: "",
    showAdd: false,
    subPageUrl: "",
    backSlashParent: true,
    search: "",
    totalRecords: 0,
    allowedEdit: false,
    addPOTypeDialog: false,
    editPOTypeDialog: false,
    deletePODialog: false,
    otype_id: 0,
    status: 0,
    isedit: false,
    isAdd: false,
    ordCodeNew: "",
    ordDescriptionNew: "",
    ordCodeUpdate: "",
    ordDescriptionUpdate: "",
    json_fields: {
      ord_code: "Order Code",
      ord_descrip: "Order Description",
      active: "Status",
    },
    json_meta: [
      [
        {
          key: "charset",
          value: "utf-8",
        },
      ],
    ],
    headers: [
      {
        text: "Order Code",
        align: "start",
        value: "ord_code",
        class: "primary customwhite--text",
        width: "10%",
      },
      {
        text: "Order Description",
        value: "ord_descrip",
        class: "primary customwhite--text",
        width: "20%",
      },

      {
        text: "Status",
        value: "active",
        class: "primary customwhite--text",
        width: "7%",
      },
      {
        text: "Actions",
        value: "actions",
        class: "primary customwhite--text",
        sortable: false,
        width: "5%",
      },
    ],
    POTypeList: [],
    projectList: [],
    isAction: true,
    editOrdCode: true,
    selectedProject: "",
    max50Rule: [(v) => (v || "").length <= 50 || "Max Length of 50 character", (v) => !!v || "Field is required"],
    max10Rule: [
      (v) => (v || "").length <= 10 || "Max Length of 10 character",
      (v) => (v || "").length >= 2 || "Min Length of 2 character",
      (v) => !!v || "Field is required",
    ],
  }),
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  mounted() {
    this.getProject();
  },
  methods: {
    //Reset Function
    resetFunction() {
      this.selectedProject = "";
      this.POTypeList = [];
      this.$refs.POTypeForm.resetValidation();
      this.totalRecords = 0;
      this.isAdd = false;
      this.isAction = true;
      this.editOrdCode = true;
      this.search = "";
      this.getProject();
    },
    //API call to get project list
    async getProject() {
      let projectData = await commonService.getProject("get", this.userId);
      this.projectList = projectData.ProjectInfo;
    },
    //Once project is selected
    projectSelected() {
      if (this.selectedProject !== "" && this.selectedProject !== undefined && this.selectedProject !== null) {
        this.isAdd = true;
        this.getPOList();
      } else if (this.selectedProject == null) {
        this.POTypeList = [];
        this.totalRecords = 0;
      }
    },
    //To get sales order list based on the project
    async getPOList() {
      this.POTypeList = [];
      this.totalRecords = 0;
      let obj = {
        UserId: parseInt(this.userId),
        pass: 1,
        proj_key: parseInt(this.selectedProject),
      };
      let showMessage = false;
      let response = await servicePO.managePOTypesList(obj, showMessage);
      if (response.message !== "NA" && response !== 400) {
        this.POTypeList = response;
        this.totalRecords = this.POTypeList.length;
      }
    },
    //Add Purchase Order Type button Click Event
    addPOTypes() {
      if (this.$refs.addPOForm.validate()) {
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        let groupdata = {
          UserId: parseInt(this.userId),
          pass: 2,
          ord_code: this.ordCodeNew,
          ord_descrip: this.ordDescriptionNew,
          proj_key: parseInt(this.selectedProject),
        };
        this.axios
          .post("/po/po_manage_po_types", groupdata)
          .then((response) => {
            if (response.status == 200 || response.status == 204) {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "success",
                isShow: true,
                message: response.data.body.message,
              };
              this.addPOTypeDialog = false;
              this.$refs.addPOForm.reset();
              this.getPOList();
              this.$store.commit("ConfigModule/Alert", Alert);
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.addPOTypeDialog = false;
              this.$refs.addPOForm.reset();
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.errorMessage = error.message;
            this.$refs.addPOForm.reset();
          });
      } else {
        this.addPOTypeDialog = true;
      }
    },
    //Update PO Type button click event
    updatePOType(isEditOrdCodeAllow) {
      if (this.$refs.EditPOform.validate()) {
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        if (this.active == true) {
          this.status = 1;
        } else {
          this.status = 0;
        }
        let groupdata = {
          pass: isEditOrdCodeAllow ? 5 : 3,
          status: parseInt(this.status),
          otype_id: parseInt(this.otype_id),
          UserId: parseInt(this.userId),
          ord_code: this.ordCodeUpdate,
          ord_descrip: this.ordDescriptionUpdate,
          proj_key: parseInt(this.selectedProject),
        };
        this.axios
          .post("/po/po_manage_po_types", groupdata)
          .then((response) => {
            if (response.status == 200 || response.status == 204) {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "success",
                isShow: true,
                message: response.data.body.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
              this.getPOList();
              this.editPOTypeDialog = false;
              this.deletePODialog = false;
              this.$refs.EditPOform.reset();
              this.editUrl = "";
              this.allowedEdit = false;
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
              this.editPOTypeDialog = false;
              this.deletePODialog = false;
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.errorMessage = error.message;
          });
      } else {
        this.editPOTypeDialog = true;
      }
    },
    //Delete Purchase Order Type
    deletePOType() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      let groupdata = {
        pass: 4,
        status: 0,
        otype_id: parseInt(this.otype_id),
        UserId: parseInt(this.userId),
        ord_code: this.ordCodeUpdate,
        ord_descrip: this.ordDescriptionUpdate,
        proj_key: parseInt(this.selectedProject),
      };
      this.axios
        .post("/po/po_manage_po_types", groupdata)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "success",
              isShow: true,
              message: response.data.body.message,
            };
            this.getPOList();
            this.deletePODialog = false;
            this.editPOTypeDialog = false;
            this.$store.commit("ConfigModule/Alert", Alert);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.deletePODialog = false;
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //To edit existing Purchase Order Type
    editItem(item) {
      this.editPOTypeDialog = true;
      this.otype_id = item.otype_id;
      this.ordCodeUpdate = item.ord_code;
      this.ordDescriptionUpdate = item.ord_descrip;
      this.active = item.active;
      this.isedit = false;
      this.editOrdCode = true;
      this.isAction = item.isaction == 0 ? false : true;
    },
    //To allow edit based on the data
    allowEdit() {
      this.allowedEdit = true;
      this.isedit = true;
      this.editOrdCode = !this.isAction;
    },
    //Close button click event in the popups
    closeDialog() {
      if (this.editPOTypeDialog) {
        this.editPOTypeDialog = false;
        this.ordCodeUpdate = "";
        this.ordDescriptionUpdate = "";
        this.$refs.EditPOform.resetValidation();
      } else if (this.addPOTypeDialog) {
        this.addPOTypeDialog = false;
        this.ordCodeNew = "";
        this.ordDescriptionNew = "";
        this.$refs.addPOForm.resetValidation();
      }
      this.allowedEdit = false;
      this.deletePODialog = false;
      this.isedit = false;
    },
    //ord code in update input event
    ordCodeUpdateChange() {
      if (this.ordCodeUpdate !== null) {
        this.ordCodeUpdate = this.ordCodeUpdate.toUpperCase();
      }
    },
    //ord code in new input event
    ordCodeNewChange() {
      if (this.ordCodeNew !== null) {
        this.ordCodeNew = this.ordCodeNew.toUpperCase();
      }
    },
    //ord description in new input event
    ordDescriptionNewChange() {
      if (this.ordDescriptionNew !== null) {
        this.ordDescriptionNew = this.ordDescriptionNew.toUpperCase();
      }
    },
    //ord description in update input event
    ordDescriptionUpdateChange() {
      if (this.ordDescriptionUpdate !== null) {
        this.ordDescriptionUpdate = this.ordDescriptionUpdate.toUpperCase();
      }
    },
    //Add or remove Favourtite
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
  },
  components: {
    breadcrumbComp,
  },
};
